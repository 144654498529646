export const seo: any = {
  login: {
    de: {
      title: "Login",
      desc: "Greifen Sie einfach auf Ihr Konto auf masimka.com zu. Melden Sie sich schnell und sicher an, um alle Funktionen zu genießen.",
      keywords:
        "Mathematik, Login, Konto zugreifen, anmelden, registrieren, sichere Plattform, Authentifizierung, Benutzerkonto, schnelles Anmelden, sicherer Zugriff",
    },
    en: {
      title: "Login",
      desc: "Easily access your account on masimka.com. Log in quickly and securely to enjoy all the features.",
      keywords:
        "mathematics, login, access account, sign in, register, secure platform, authentication, user account, quick login, secure access",
    },
    es: {
      title: "Login",
      desc: "Accede fácilmente a tu cuenta en masimka.com. Inicia sesión de forma rápida y segura para disfrutar de todas las funcionalidades.",
      keywords:
        "matematicas, inicio de sesión, acceder cuenta, login, ingresar, registrarse, plataforma segura, autenticación, cuenta de usuario, iniciar sesión rápido, acceso seguro",
    },
    it: {
      title: "Login",
      desc: "Accedi facilmente al tuo account su masimka.com. Effettua il login in modo rapido e sicuro per godere di tutte le funzionalità.",
      keywords:
        "matematica, login, accedere al conto, registrazione, piattaforma sicura, autenticazione, conto utente, login veloce, accesso sicuro",
    },
    jp: {
      title: "ログイン",
      desc: "masimka.comで簡単にアカウントにアクセスできます。すべての機能を楽しむために、迅速かつ安全にログインしてください。",
      keywords:
        "数学, ログイン, アカウントアクセス, サインイン, 登録, 安全なプラットフォーム, 認証, ユーザーアカウント, 迅速なログイン, 安全なアクセス",
    },
    pt: {
      title: "Login",
      desc: "Acesse facilmente sua conta em masimka.com. Faça login de forma rápida e segura para aproveitar todos os recursos.",
      keywords:
        "matemática, login, acessar conta, registrar, plataforma segura, autenticação, conta de usuário, login rápido, acesso seguro",
    },
    ru: {
      title: "Вход",
      desc: "Легко войдите в свою учетную запись на masimka.com. Войдите быстро и безопасно, чтобы воспользоваться всеми функциями.",
      keywords:
        "математика, вход, доступ к учетной записи, вход в систему, регистрация, безопасная платформа, аутентификация, учетная запись пользователя, быстрый вход, безопасный доступ",
    },
    tw: {
      title: "登入",
      desc: "輕鬆訪問您的 masimka.com 帳戶。快速且安全地登入，以享受所有功能。",
      keywords:
        "數學, 登入, 訪問帳戶, 登錄, 註冊, 安全平台, 認證, 用戶帳戶, 快速登入, 安全訪問",
    },
  },
  home: {
    de: {
      title: "Startseite",
      desc: "Entdecke grundlegende mathematische Operationen wie Addition, Subtraktion, Multiplikation und Division in diesem interaktiven Lernmodul. Schritt für Schritt wirst du dein Verständnis verbessern und essentielle mathematische Fähigkeiten mit praktischen Beispielen und Übungen anwenden. Perfekt für Anfänger und diejenigen, die Schlüsselkonzepte wiederholen möchten. Fang jetzt an zu lernen!",
      keywords:
        "grundlegende mathematische Operationen, Addition, Subtraktion, Multiplikation, Division, Mathematik lernen, Mathematikübungen, interaktives Lernen, Mathematik für Anfänger, praktische Mathematikbeispiele, mathematische Fähigkeiten, mathematische Konzepte, Mathematikmodul, grundlegende Mathematik lernen",
    },
    en: {
      title: "Home",
      desc: "Explore basic mathematical operations like addition, subtraction, multiplication, and division in this interactive learning module. Step by step, enhance your understanding and apply essential math skills with practical examples and exercises. Perfect for beginners and those reviewing key concepts. Start learning now!",
      keywords:
        "basic math operations, addition, subtraction, multiplication, division, learn math, math exercises, interactive learning, beginner math, practical math examples, math skills, math concepts, math module, learn basic math",
    },
    es: {
      title: "Inicio",
      desc: "Explora las operaciones matemáticas básicas como la suma, resta, multiplicación y división en este módulo de aprendizaje interactivo. Paso a paso, mejora tu comprensión y aplica habilidades matemáticas esenciales con ejemplos prácticos y ejercicios. Perfecto para principiantes y para quienes revisan conceptos clave. ¡Comienza a aprender ahora!",
      keywords:
        "operaciones matemáticas básicas, suma, resta, multiplicación, división, aprender matemáticas, ejercicios de matemáticas, aprendizaje interactivo, matemáticas para principiantes, ejemplos prácticos de matemáticas, habilidades matemáticas, conceptos matemáticos, módulo de matemáticas, aprender matemáticas básicas",
    },
    it: {
      title: "Inizio",
      desc: "Esplora le operazioni matematiche di base come addizione, sottrazione, moltiplicazione e divisione in questo modulo di apprendimento interattivo. Passo dopo passo, migliora la tua comprensione e applica abilità matematiche essenziali con esempi pratici ed esercizi. Perfetto per principianti e per chi vuole rivedere concetti chiave. Inizia a imparare ora!",
      keywords:
        "operazioni matematiche di base, addizione, sottrazione, moltiplicazione, divisione, imparare la matematica, esercizi di matematica, apprendimento interattivo, matematica per principianti, esempi pratici di matematica, abilità matematiche, concetti matematici, modulo di matematica, imparare la matematica di base",
    },
    jp: {
      title: "ホーム",
      desc: "このインタラクティブな学習モジュールで、加算、減算、乗算、除算などの基本的な数学的操作を探求しましょう。ステップバイステップで理解を深め、実践的な例と練習問題を通じて、数学の基本的なスキルを身につけましょう。初心者や重要な概念を復習したい方に最適です。今すぐ学び始めましょう！",
      keywords:
        "基本的な数学的操作, 加算, 減算, 乗算, 除算, 数学を学ぶ, 数学の練習, インタラクティブな学習, 初心者向け数学, 実践的な数学の例, 数学的スキル, 数学の概念, 数学モジュール, 基本的な数学を学ぶ",
    },
    pt: {
      title: "Início",
      desc: "Explore as operações matemáticas básicas, como adição, subtração, multiplicação e divisão, neste módulo de aprendizado interativo. Passo a passo, melhore sua compreensão e aplique habilidades matemáticas essenciais com exemplos práticos e exercícios. Perfeito para iniciantes e para quem deseja revisar conceitos-chave. Comece a aprender agora!",
      keywords:
        "operações matemáticas básicas, adição, subtração, multiplicação, divisão, aprender matemática, exercícios de matemática, aprendizado interativo, matemática para iniciantes, exemplos práticos de matemática, habilidades matemáticas, conceitos matemáticos, módulo de matemática, aprender matemática básica",
    },
    ru: {
      title: "Главная",
      desc: "Изучите основные математические операции, такие как сложение, вычитание, умножение и деление, в этом интерактивном учебном модуле. Шаг за шагом улучшайте свое понимание и применяйте основные математические навыки с помощью практических примеров и упражнений. Идеально для начинающих и тех, кто хочет повторить ключевые концепции. Начните учиться прямо сейчас!",
      keywords:
        "основные математические операции, сложение, вычитание, умножение, деление, изучать математику, математические упражнения, интерактивное обучение, математика для начинающих, практические примеры математики, математические навыки, математические концепции, учебный модуль по математике, изучение основ математики",
    },
    tw: {
      title: "首頁",
      desc: "在這個互動學習模組中，探索基本的數學運算，如加法、減法、乘法和除法。一步一步提升你的理解，並通過實際例子和練習應用基本的數學技能。適合初學者以及想要複習關鍵概念的人。立即開始學習吧！",
      keywords:
        "基本數學運算, 加法, 減法, 乘法, 除法, 學習數學, 數學練習, 互動學習, 初學者數學, 實際數學例子, 數學技能, 數學概念, 數學模組, 學習基本數學",
    },
  },
  privacy_policy: {
    de: {
      title: "Datenschutzrichtlinie",
      desc: "Lesen Sie unsere Datenschutzrichtlinie, um zu erfahren, wie wir Ihre persönlichen Daten schützen und verwenden. Hier finden Sie Informationen zur Erhebung, Nutzung und Speicherung von Daten auf unserer Website sowie Ihre Rechte und Optionen bezüglich Ihrer Privatsphäre. Ihre Sicherheit und Ihr Vertrauen sind unsere Priorität.",
      keywords:
        "Datenschutzrichtlinie, Datenschutz, Online-Privatsphäre, Datenerhebung, Datennutzung, Datenspeicherung, Datenschutzrechte, Datensicherheit, Benutzerdaten, Informationsschutz, Datenschutzbedingungen, Datenschutzrichtlinie",
    },
    en: {
      title: "Privacy Policy",
      desc: "Read our Privacy Policy to learn how we protect and use your personal data. Here you will find information about the collection, use, and storage of data on our website, as well as your rights and options regarding your privacy. Your security and trust are our priority.",
      keywords:
        "Privacy policy, data protection, online privacy, data collection, data use, data storage, privacy rights, data security, user privacy, information protection, privacy terms, data protection policy",
    },
    es: {
      title: "Política de privacidad",
      desc: "Lee nuestra Política de Privacidad para conocer cómo protegemos y utilizamos tus datos personales. Aquí encontrarás información sobre la recopilación, el uso y el almacenamiento de datos en nuestro sitio web, así como tus derechos y opciones sobre tu privacidad. Tu seguridad y confianza son nuestra prioridad.",
      keywords:
        "Política de privacidad, protección de datos, privacidad en línea, recopilación de datos, uso de datos, almacenamiento de datos, derechos de privacidad, seguridad de datos, privacidad del usuario, protección de la información, términos de privacidad, política de protección de datos",
    },
    it: {
      title: "Politica sulla privacy",
      desc: "Leggi la nostra Politica sulla Privacy per scoprire come proteggiamo e utilizziamo i tuoi dati personali. Qui troverai informazioni sulla raccolta, l'uso e l'archiviazione dei dati sul nostro sito web, oltre ai tuoi diritti e opzioni sulla tua privacy. La tua sicurezza e fiducia sono la nostra priorità.",
      keywords:
        "Politica sulla privacy, protezione dei dati, privacy online, raccolta dei dati, uso dei dati, archiviazione dei dati, diritti alla privacy, sicurezza dei dati, privacy dell'utente, protezione delle informazioni, termini sulla privacy, politica di protezione dei dati",
    },
    jp: {
      title: "プライバシーポリシー",
      desc: "プライバシーポリシーをお読みいただき、どのようにしてあなたの個人データを保護し、使用しているかを確認してください。ここでは、当サイトでのデータの収集、使用、保存に関する情報や、あなたのプライバシーに関する権利と選択肢について説明します。あなたの安全と信頼は私たちの最優先事項です。",
      keywords:
        "プライバシーポリシー, データ保護, オンラインプライバシー, データ収集, データ使用, データ保存, プライバシー権, データセキュリティ, ユーザープライバシー, 情報保護, プライバシー条件, データ保護ポリシー",
    },
    pt: {
      title: "Política de privacidade",
      desc: "Leia nossa Política de Privacidade para entender como protegemos e usamos seus dados pessoais. Aqui, você encontrará informações sobre a coleta, o uso e o armazenamento de dados em nosso site, assim como seus direitos e opções sobre sua privacidade. Sua segurança e confiança são nossa prioridade.",
      keywords:
        "Política de privacidade, proteção de dados, privacidade online, coleta de dados, uso de dados, armazenamento de dados, direitos de privacidade, segurança de dados, privacidade do usuário, proteção das informações, termos de privacidade, política de proteção de dados",
    },
    ru: {
      title: "Политика конфиденциальности",
      desc: "Прочитайте нашу Политику конфиденциальности, чтобы узнать, как мы защищаем и используем ваши личные данные. Здесь вы найдете информацию о сборе, использовании и хранении данных на нашем сайте, а также о ваших правах и возможностях в отношении вашей конфиденциальности. Ваша безопасность и доверие — наш приоритет.",
      keywords:
        "Политика конфиденциальности, защита данных, онлайн-конфиденциальность, сбор данных, использование данных, хранение данных, права конфиденциальности, безопасность данных, конфиденциальность пользователя, защита информации, условия конфиденциальности, политика защиты данных",
    },
    tw: {
      title: "隱私政策",
      desc: "閱讀我們的隱私政策，了解我們如何保護和使用您的個人資料。在此，您將找到有關我們網站上數據的收集、使用和存儲的資訊，以及您在隱私方面的權利和選擇。您的安全和信任是我們的首要任務。",
      keywords:
        "隱私政策, 數據保護, 在線隱私, 數據收集, 數據使用, 數據存儲, 隱私權利, 數據安全, 用戶隱私, 資訊保護, 隱私條款, 數據保護政策",
    },
  },
  mathematics_operations: {
    de: {
      title: "Mathematische Operationen",
      desc: "Lerne grundlegende mathematische Operationen wie Addition, Subtraktion, Multiplikation und Division in unserem interaktiven Bereich. Mit praktischen Beispielen und Übungen wirst du deine Fähigkeiten und dein Verständnis in diesen wesentlichen Operationen verbessern. Ideal für Anfänger und für diejenigen, die grundlegende Mathematikkonzepte wiederholen möchten.",
      keywords:
        "mathematische Operationen, Addition, Subtraktion, Multiplikation, Division, Mathematik lernen, grundlegende Operationen, Mathematikübungen, Mathematikbeispiele, mathematische Fähigkeiten, Mathematik für Anfänger, Übung von Operationen, mathematische Konzepte, arithmetische Operationen, grundlegende Operationen lernen",
    },
    en: {
      title: "Mathematical Operations",
      desc: "Learn basic mathematical operations like addition, subtraction, multiplication, and division in our interactive section. With practical examples and exercises, you will improve your skills and understanding of these essential operations. Ideal for beginners and those who want to review key math concepts.",
      keywords:
        "mathematical operations, addition, subtraction, multiplication, division, learn math, basic operations, math exercises, math examples, math skills, math for beginners, practice operations, math concepts, arithmetic operations, learn basic operations",
    },
    es: {
      title: "Operaciones matematicas",
      desc: "Aprende las operaciones matemáticas básicas como la suma, resta, multiplicación y división en nuestra sección interactiva. Con ejemplos prácticos y ejercicios, mejorarás tus habilidades y comprensión en estas operaciones esenciales. Ideal para principiantes y para quienes desean repasar conceptos clave de matemáticas.",
      keywords:
        "operaciones matemáticas, suma, resta, multiplicación, división, aprender matemáticas, operaciones básicas, ejercicios de matemáticas, ejemplos de matemáticas, habilidades matemáticas, matemáticas para principiantes, práctica de operaciones, conceptos matemáticos, operaciones aritméticas, aprender operaciones básicas",
    },
    it: {
      title: "Operazioni matematiche",
      desc: "Impara le operazioni matematiche di base come addizione, sottrazione, moltiplicazione e divisione nella nostra sezione interattiva. Con esempi pratici ed esercizi, migliorerai le tue abilità e comprensione di queste operazioni essenziali. Ideale per principianti e per chi desidera rivedere i concetti chiave di matematica.",
      keywords:
        "operazioni matematiche, addizione, sottrazione, moltiplicazione, divisione, imparare matematica, operazioni di base, esercizi di matematica, esempi di matematica, abilità matematiche, matematica per principianti, pratica delle operazioni, concetti matematici, operazioni aritmetiche, imparare operazioni di base",
    },
    jp: {
      title: "数学の操作",
      desc: "私たちのインタラクティブセクションで、加算、減算、乗算、除算などの基本的な数学的操作を学びましょう。実際の例と練習問題を通じて、これらの重要な操作に関する理解とスキルを向上させます。初心者や数学の重要な概念を復習したい方に最適です。",
      keywords:
        "数学的操作, 加算, 減算, 乗算, 除算, 数学を学ぶ, 基本的な操作, 数学の練習, 数学の例, 数学的スキル, 初心者向け数学, 操作の練習, 数学的概念, 算術操作, 基本的な操作を学ぶ",
    },
    pt: {
      title: "Operações matemáticas",
      desc: "Aprenda as operações matemáticas básicas como adição, subtração, multiplicação e divisão em nossa seção interativa. Com exemplos práticos e exercícios, você melhorará suas habilidades e compreensão dessas operações essenciais. Ideal para iniciantes e para quem deseja revisar conceitos-chave de matemática.",
      keywords:
        "operações matemáticas, adição, subtração, multiplicação, divisão, aprender matemática, operações básicas, exercícios de matemática, exemplos de matemática, habilidades matemáticas, matemática para iniciantes, prática de operações, conceitos matemáticos, operações aritméticas, aprender operações básicas",
    },
    ru: {
      title: "Математические операции",
      desc: "Изучите основные математические операции, такие как сложение, вычитание, умножение и деление, в нашем интерактивном разделе. С помощью практических примеров и упражнений вы улучшите свои навыки и понимание этих основных операций. Идеально для новичков и тех, кто хочет повторить ключевые математические концепции.",
      keywords:
        "математические операции, сложение, вычитание, умножение, деление, изучать математику, основные операции, упражнения по математике, примеры математики, математические навыки, математика для новичков, практика операций, математические концепции, арифметические операции, изучение основных операций",
    },
    tw: {
      title: "數學運算",
      desc: "在我們的互動區域學習基本的數學運算，如加法、減法、乘法和除法。通過實際例子和練習，您將提高對這些基本運算的理解和技能。適合初學者和希望複習數學關鍵概念的人。",
      keywords:
        "數學運算, 加法, 減法, 乘法, 除法, 學習數學, 基本運算, 數學練習, 數學例子, 數學技能, 初學者數學, 運算練習, 數學概念, 算術運算, 學習基本運算",
    },
  },
  404: {
    de: {
      title: "404",
      desc: "Entdecke grundlegende mathematische Operationen wie Addition, Subtraktion, Multiplikation und Division in diesem interaktiven Lernmodul. Schritt für Schritt wirst du dein Verständnis verbessern und essentielle mathematische Fähigkeiten mit praktischen Beispielen und Übungen anwenden. Perfekt für Anfänger und diejenigen, die Schlüsselkonzepte wiederholen möchten. Fang jetzt an zu lernen!",
      keywords:
        "grundlegende mathematische Operationen, Addition, Subtraktion, Multiplikation, Division, Mathematik lernen, Mathematikübungen, interaktives Lernen, Mathematik für Anfänger, praktische Mathematikbeispiele, mathematische Fähigkeiten, mathematische Konzepte, Mathematikmodul, grundlegende Mathematik lernen",
    },
    en: {
      title: "404",
      desc: "Explore basic mathematical operations like addition, subtraction, multiplication, and division in this interactive learning module. Step by step, enhance your understanding and apply essential math skills with practical examples and exercises. Perfect for beginners and those reviewing key concepts. Start learning now!",
      keywords:
        "basic math operations, addition, subtraction, multiplication, division, learn math, math exercises, interactive learning, beginner math, practical math examples, math skills, math concepts, math module, learn basic math",
    },
    es: {
      title: "404",
      desc: "Explora las operaciones matemáticas básicas como la suma, resta, multiplicación y división en este módulo de aprendizaje interactivo. Paso a paso, mejora tu comprensión y aplica habilidades matemáticas esenciales con ejemplos prácticos y ejercicios. Perfecto para principiantes y para quienes revisan conceptos clave. ¡Comienza a aprender ahora!",
      keywords:
        "operaciones matemáticas básicas, suma, resta, multiplicación, división, aprender matemáticas, ejercicios de matemáticas, aprendizaje interactivo, matemáticas para principiantes, ejemplos prácticos de matemáticas, habilidades matemáticas, conceptos matemáticos, módulo de matemáticas, aprender matemáticas básicas",
    },
    it: {
      title: "404",
      desc: "Esplora le operazioni matematiche di base come addizione, sottrazione, moltiplicazione e divisione in questo modulo di apprendimento interattivo. Passo dopo passo, migliora la tua comprensione e applica abilità matematiche essenziali con esempi pratici ed esercizi. Perfetto per principianti e per chi vuole rivedere concetti chiave. Inizia a imparare ora!",
      keywords:
        "operazioni matematiche di base, addizione, sottrazione, moltiplicazione, divisione, imparare la matematica, esercizi di matematica, apprendimento interattivo, matematica per principianti, esempi pratici di matematica, abilità matematiche, concetti matematici, modulo di matematica, imparare la matematica di base",
    },
    jp: {
      title: "404",
      desc: "このインタラクティブな学習モジュールで、加算、減算、乗算、除算などの基本的な数学的操作を探求しましょう。ステップバイステップで理解を深め、実践的な例と練習問題を通じて、数学の基本的なスキルを身につけましょう。初心者や重要な概念を復習したい方に最適です。今すぐ学び始めましょう！",
      keywords:
        "基本的な数学的操作, 加算, 減算, 乗算, 除算, 数学を学ぶ, 数学の練習, インタラクティブな学習, 初心者向け数学, 実践的な数学の例, 数学的スキル, 数学の概念, 数学モジュール, 基本的な数学を学ぶ",
    },
    pt: {
      title: "404",
      desc: "Explore as operações matemáticas básicas, como adição, subtração, multiplicação e divisão, neste módulo de aprendizado interativo. Passo a passo, melhore sua compreensão e aplique habilidades matemáticas essenciais com exemplos práticos e exercícios. Perfeito para iniciantes e para quem deseja revisar conceitos-chave. Comece a aprender agora!",
      keywords:
        "operações matemáticas básicas, adição, subtração, multiplicação, divisão, aprender matemática, exercícios de matemática, aprendizado interativo, matemática para iniciantes, exemplos práticos de matemática, habilidades matemáticas, conceitos matemáticos, módulo de matemática, aprender matemática básica",
    },
    ru: {
      title: "404",
      desc: "Изучите основные математические операции, такие как сложение, вычитание, умножение и деление, в этом интерактивном учебном модуле. Шаг за шагом улучшайте свое понимание и применяйте основные математические навыки с помощью практических примеров и упражнений. Идеально для начинающих и тех, кто хочет повторить ключевые концепции. Начните учиться прямо сейчас!",
      keywords:
        "основные математические операции, сложение, вычитание, умножение, деление, изучать математику, математические упражнения, интерактивное обучение, математика для начинающих, практические примеры математики, математические навыки, математические концепции, учебный модуль по математике, изучение основ математики",
    },
    tw: {
      title: "404",
      desc: "在這個互動學習模組中，探索基本的數學運算，如加法、減法、乘法和除法。一步一步提升你的理解，並通過實際例子和練習應用基本的數學技能。適合初學者以及想要複習關鍵概念的人。立即開始學習吧！",
      keywords:
        "基本數學運算, 加法, 減法, 乘法, 除法, 學習數學, 數學練習, 互動學習, 初學者數學, 實際數學例子, 數學技能, 數學概念, 數學模組, 學習基本數學",
    },
  },
};
