import { HomeOutlined, Policy } from "@mui/icons-material";
import { iModuleRoute } from "../side-menu-state";
import { MenuItem } from "../components/interface";
import { lazy } from "react";
import SuspenseLoader from "../../loader/suspense-loader";

const MenuItems: (t: Function) => MenuItem[] = (t: Function) => {
  return [
    {
      name: t("app.home.title"),
      iconElement: (style: any) => <HomeOutlined style={style} />,
      path: "/",
      key: "home",
    },
  ];
};

const Legal: (t: Function) => MenuItem[] = (t: Function) => {
  return [
    {
      name: t("app.common.privacy_policy"),
      iconElement: (style: any) => <Policy style={style} />,
      path: "/privacy_policy",
      key: "legal",
    },
  ];
};

const LegalViewPage = lazy(() => import("../../../pages/PrivacyPolicy"));
const LegalPage = () => (
  <SuspenseLoader>
    <LegalViewPage />
  </SuspenseLoader>
);

const Routes: iModuleRoute[] =  [
  {
    path: `mathematics/operations`,
    element: LegalPage,
  },
];

export { MenuItems, Legal, Routes };
