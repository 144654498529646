import { createContext } from "react";

export enum TokenError {
  NO_TOKEN = 1, // No existe token guardado, (como que nunca se hubiera iniciado sesión)
  EXPIRED_TOKEN = 2 // Existe un token guardado en localstorage, pero ya está vencido
}

export interface IUserContextValue {
  tokenError?: TokenError
}

const UserContextValue: IUserContextValue = {};

const UserContext = createContext(UserContextValue);

export { UserContext, UserContextValue };
