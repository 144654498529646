import React, { Suspense } from "react";
// import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./i18nextConf";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./apollo-client";
import { createRoot } from "react-dom/client";
import FullLoader from "./components/loader/loader";

const loadingMarkup = <FullLoader dismiss={false} />;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Suspense fallback={loadingMarkup}>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </Suspense>
);
