import { lazy, memo } from "react";
import { Routes, Route } from "react-router-dom";
import { iModuleRoute } from "./components/side-menu/side-menu-state";
import SuspenseLoader from "./components/loader/suspense-loader";

const NotFoundLazy = lazy(() => import("./pages/not-found"));
const NotFound = () => (
  <SuspenseLoader>
    <NotFoundLazy />
  </SuspenseLoader>
);

const LoginPageLazy = lazy(() => import("./pages/login"));
const LoginPage = () => (
  <SuspenseLoader>
    <LoginPageLazy />
  </SuspenseLoader>
);

const MainLayoutLazy = lazy(() => import("./pages/layouts/MainLayout"));
const MainLayout = () => (
  <SuspenseLoader>
    <MainLayoutLazy />
  </SuspenseLoader>
);

const HomeLazy = lazy(() => import("./pages/Home"));
const Home = () => (
  <SuspenseLoader>
    <HomeLazy />
  </SuspenseLoader>
);

const PrivacyPolicyLazy = lazy(() => import("./pages/PrivacyPolicy"));
const PrivacyPolicy = () => (
  <SuspenseLoader>
    <PrivacyPolicyLazy />
  </SuspenseLoader>
);

interface RoutingProps {
  routes: iModuleRoute[];
}

const Routing = memo((props: RoutingProps) => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="login" element={<LoginPage />} />

      <Route path="/" element={<MainLayout />}>
        <Route path="" element={<Home />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        {props.routes.map((route, i) => (
          <Route key={i} path={route.path} element={route.element()}>
            {route?.subRoutes?.map((sRoute, j) => (
              <Route key={j} path={sRoute.path} element={sRoute.element()} />
            ))}
          </Route>
        ))}
      </Route>
    </Routes>
  );
});

export default Routing;
