import React, { CSSProperties, useEffect, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

interface SnackMessageProps {
  open: boolean;
  onClose: () => void;
  message: string;
  duration?: number;
  severity?: AlertColor;
}

class SnackMessageControllerClass {
  private __setSnack: Function = () => {};
  private __state: any = {};
  __setState(setState: Function, state: any) {
    this.__setSnack = setState;
    this.__state = state;
  }
  show(message: string, severity?: AlertColor, duration = 7000) {
    this.__setSnack({
      ...this.__state,
      message,
      severity,
      duration,
      show: true,
    });
  }
}

const SnackMessageController = new SnackMessageControllerClass();

function CustomSnack() {
  const [snack, setSnack] = useState({
    show: false,
    message: "",
    severity: "success",
    duration: 7000,
  });

  SnackMessageController.__setState(setSnack, snack);

  const onClose = () => setSnack({ ...snack, show: false });

  return (
    <>
      <SnackMessage
        open={snack.show}
        onClose={onClose}
        message={snack.message}
        duration={snack.duration}
        severity={
          snack.severity === "info"
            ? "info"
            : snack.severity === "error"
            ? "error"
            : snack.severity === "warning"
            ? "warning"
            : "success"
        }
      />
    </>
  );
}

export { SnackMessageController, CustomSnack };

export default function SnackMessage(props: SnackMessageProps) {
  const [snack, setSnack] = useState({
    open: false,
  });

  const handleClose = () => {
    props.onClose();
  };

  const style: CSSProperties = {};

  if (props.severity === "success") {
    style.backgroundColor = "#8ed11f";
  }

  useEffect(() => {
    setSnack({ ...snack, open: props.open });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Snackbar
      open={snack.open}
      autoHideDuration={600000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={props.severity}
        sx={{ ...style, width: "100%" }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}
