import { ApolloClient, DefaultOptions, InMemoryCache } from "@apollo/client";
import enviroment from "../enviroment";
import { iSession, SESSION_KEY } from "../services/session";

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "none",
  },
};

let headers: any | undefined;
try {
  const sessionStr = localStorage.getItem(SESSION_KEY);
  const session: iSession = JSON.parse(sessionStr || "");
  headers = {
    Authorization: "Bearer " + session.token,
    "Access-Control-Allow-Origin": "*",
  };
} catch (e) {}

const apolloClient = new ApolloClient({
  uri: enviroment.apiUrl + "/graphql",
  cache: new InMemoryCache(),
  headers,
  defaultOptions,
});

export default apolloClient;
