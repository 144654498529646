import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const availableLanguages = ["es", "en", "fr", "de", "it", "pt", "ru", "tw", "jp"];

i18n
  .use(HttpApi) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    supportedLngs: availableLanguages,
    fallbackLng: "es",
    debug: false,
    partialBundledLanguages: true,
    load: "currentOnly",
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    cache: {
      enabled: true,
      prefix: "i18next_res_", // Key prefix in localStorage
      expirationTime: 7 * 24 * 60 * 60 * 1000, // One week
      versions: {}, // Versions of translations to be cached
    },
    react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    initImmediate: true,
  });

export default i18n;
