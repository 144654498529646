import React, { Suspense, useEffect, useState } from "react";
import "./index.css";
import Loader from "./loader";

interface SuspenseLoaderProps {
  children: JSX.Element;
}

export default function SuspenseLoader(props: SuspenseLoaderProps) {
  return (
    <Suspense fallback={<Loader dismiss={true} />}>
      <SuspenseChildrenFade>{props.children}</SuspenseChildrenFade>
    </Suspense>
  );
}

// Este componente, al cargar muestra el loader y lo quita con un desvanecimiento
const SuspenseChildrenFade = (props: SuspenseLoaderProps) => {
  return (
    <>
      <LoaderFade />
      {props.children}
    </>
  );
};

const LoaderFade = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const t = setTimeout(() => {
      setLoading(false);
    }, 50);
    return () => {
      clearTimeout(t);
    };
  }, []);
  return <Loader dismiss={loading} />;
};
