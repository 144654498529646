import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0d2762",
    },
    secondary: {
      main: "#e75e27",
    },
    error: {
      main: "#f14b4c",
    },
    background: {
      default: "#f1f5f9",
      paper: "#0d2a62",
    },
    text: {
      primary: "#fff",
      secondary: "#e6e6e6",
      disabled: "#c4c4c4de",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "rgba(255, 255, 255, 0.7)",
    },
    error: {
      main: "#f14b4c",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    background: {
      default: "#121212",
      paper: "#121212",
    },
    text: {
      primary: "#fff",
      secondary: "#e6e6e6",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
  },
});
