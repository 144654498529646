import { googleLogout } from "@react-oauth/google";
import jwt from "jwt-decode";

export const SESSION_KEY = "__session__";
export const SESSION_SITE_KEY = "__siteData__";

export interface iSession {
  token: string;
  id: number;
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  picture: string;
}

export default abstract class SessionService {
  public static saveSession(token: string): Promise<boolean> {
    const user: any = jwt(token);

    return new Promise((resolve) => {
      const session: iSession = {
        ...user,
        token,
      };
      localStorage.setItem(SESSION_KEY, JSON.stringify(session));
      // Esperamos este tiempo, para estar 100% seguro que se guardó la sesión
      setTimeout(() => resolve(true), 50);
    });
  }

  public static getSession(): Promise<iSession | null> {
    return new Promise((resolve) => {
      const session = localStorage.getItem(SESSION_KEY);
      if (session) {
        resolve(JSON.parse(session));
      } else {
        resolve(null);
      }
    });
  }

  public static removeSession(): Promise<boolean> {
    return new Promise((resolve) => {
      localStorage.removeItem(SESSION_KEY);
      googleLogout();
      resolve(true);
    });
  }

  public static async isValidSession(
    graphqlQuery: Function
  ): Promise<boolean | null> {
    const session = await this.getSession();
    if (session) {
      return graphqlQuery(session.token).catch((e: any) => {
        console.error("Error al validar token", e);
        return false;
      });
    } else {
      return null;
    }
  }

  public static async saveSessionData(data: {
    [key: string]: any;
  }): Promise<boolean> {
    const sessionData = await this.getSessionData();
    return new Promise((resolve) => {
      const session: { [key: string]: any } = {
        ...sessionData,
        ...data,
      };
      localStorage.setItem(SESSION_SITE_KEY, JSON.stringify(session));
      // Esperamos este tiempo, para estar 100% seguro que se guardó la sesión
      setTimeout(() => resolve(true), 50);
    });
  }

  public static getSessionData(): Promise<{ [key: string]: any } | null> {
    return new Promise((resolve) => {
      const session = localStorage.getItem(SESSION_SITE_KEY);
      if (session) {
        resolve(JSON.parse(session));
      } else {
        resolve(null);
      }
    });
  }
}
