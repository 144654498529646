import React, { CSSProperties } from "react";
import "./index.css";
import BeatLoader from "react-spinners/BeatLoader";

function LoaderAbsolute(props: {
  fixed?: boolean;
  zIndex?: number;
  backgroundColor?: string;
  borderRadius?: number;
}) {
  const style: CSSProperties = {};

  if (props.fixed) {
    style.position = "fixed";
  }

  if (props.zIndex) {
    style.zIndex = props.zIndex;
  }

  if (props.backgroundColor) {
    style.backgroundColor = props.backgroundColor;
  }

  if (props.borderRadius) {
    style.borderRadius = props.borderRadius;
  }

  return (
    <div className="loaderAbsolute" style={style}>
      <div style={{ margin: "auto" }}>
      <BeatLoader />
      </div>
    </div>
  );
}

export { LoaderAbsolute };
