import React, { useEffect, useState } from "react";
import "./index.css";
import { BeatLoader } from "react-spinners";
import { useTheme } from "@mui/material";
import style from "./loader.module.css";

interface iLoaderProps {
  dismiss: boolean;
}

export default function Loader(props: iLoaderProps) {
  const theme = useTheme();
  const [dismiss, setDismiss] = useState(props.dismiss);

  useEffect(() => {
    setDismiss(props.dismiss);
  }, [props.dismiss]);

  return (
    <div className={`${style.full} ${!dismiss ? style.hidden : ""}`}>
      <div style={{ display: "flex", flex: 1, height: "100%" }}>
        <div className="loader">
          <BeatLoader color={theme.palette.primary.main} />
        </div>
      </div>
    </div>
  );
}
