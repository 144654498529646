import { development } from "../env.dev";
import { production } from "../env.prod";

const _enviroment = {
  development,
  production,
};

const mode = process.env.NODE_ENV;
const enviroment = mode ? (_enviroment as any)[mode] : _enviroment.production;

export default enviroment;
