import {
  MathematicsMenuItems,
  MathematicsRoutes,
} from "../../../modules/mathematics/mathematics-operations/install";
import { MenuItem } from "../components/interface";
import { iModuleRoute } from "../side-menu-state";
import { Legal, MenuItems } from "./menu-items";

// menu items
const listMenuItems = [MenuItems, MathematicsMenuItems, Legal];
// module routes
const listRoutes: any = [MathematicsRoutes];

// process module routes
let ModulesRoutes: iModuleRoute[] = [];
listRoutes.forEach((routes: any) => {
  ModulesRoutes = ModulesRoutes.concat(routes);
});

const GetMenuItems = (t: Function) => {
  let MenuItems: MenuItem[] = [];
  listMenuItems.forEach((items) => {
    MenuItems = MenuItems.concat(items(t));
  });
  return MenuItems;
};

export { GetMenuItems, ModulesRoutes };
