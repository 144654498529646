import { lazy } from "react";
import SuspenseLoader from "../../../../components/loader/suspense-loader";
import { iModuleRoute } from "../../../../components/side-menu/side-menu-state";
import { MenuItem } from "../../../../components/side-menu/components/interface";

const ViewPage = lazy(() => import("../pages/index"));
const MathematicsOperationPage = () => (
  <SuspenseLoader>
    <ViewPage />
  </SuspenseLoader>
);

const MathematicsRoutes: iModuleRoute[] = [
  {
    path: `mathematics/operations`,
    element: MathematicsOperationPage,
  },
];

const MathematicsMenuItems: (t: Function) => MenuItem[] = (t: Function) => {
  return [
    {
      name: t("app.activities.mathematics.title"),
      path: "/mathematics/operations",
      key: "mathematics",
      subItem: [
        {
          name: t("app.activities.mathematics.operations.title"),
          path: "/mathematics/operations",
          key: "mathematics_operation",
        },
      ],
    },
  ];
};

export { MathematicsMenuItems, MathematicsRoutes };
