import React, { useState } from "react";
import { LoaderAbsolute } from "..";

class LoaderComponentControllerClass {
  __setState: Function = () => {};

  __updateState(setState: Function) {
    this.__setState = setState;
  }

  show() {
    this.__setState(true);
  }

  hide() {
    this.__setState(false);
  }
}

const LoaderComponentController = new LoaderComponentControllerClass();

function LoaderComponent() {
  const [show, setShow] = useState(false);

  LoaderComponentController.__updateState(setShow);

  if (!show) return null;

  return <LoaderAbsolute fixed zIndex={1500} />;
}

export { LoaderComponent, LoaderComponentController };
