import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useRef, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { darkTheme, lightTheme } from "./theme/theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Loader from "./components/loader/loader";
import { iModuleRoute } from "./components/side-menu/side-menu-state";
import AppContainer from "./pages/app";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { LoaderComponent } from "./components/loader/loader-controller/loader-component";
import Routing from "./routing";
import { UserContext, UserContextValue } from "./services/auth/user-context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ModulesRoutes } from "./components/side-menu/menu/menu-list";
import { Box } from "@mui/material";
import SessionService from "./services/session";
import enviroment from "./enviroment";
import { seo } from "./seo";
import i18n from "./i18nextConf";

class AppControllerClass {
  toggleTheme() {}
  setSection(_name: string) {}
  isDarkMode() {
    return true;
  }
}

export const AppController = new AppControllerClass();

function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [moduleRoutes, setModuleRoutes] = useState<iModuleRoute[]>(
    new Array<iModuleRoute>()
  );
  const [section, setSection] = useState<string>("");

  const timeoutRef = useRef<any>(null);

  const updateModuleRoutes = () => {
    return setModuleRoutes(ModulesRoutes);
  };

  AppController.toggleTheme = () => {
    toggleTheme();
  };

  AppController.isDarkMode = () => {
    return isDarkMode;
  };

  AppController.setSection = (_name: string) => {
    setSection(_name);
  };

  const toggleTheme = () => {
    SessionService.saveSessionData({ isDarkMode: !isDarkMode });
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    SessionService.getSessionData().then((data) => {
      setIsDarkMode(data?.isDarkMode ? true : false);
    });
    updateModuleRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId="517211816966-q0j6q7a28hste9splcu0v54vm87e5h1g.apps.googleusercontent.com">
      <CssBaseline />
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <Box>
          <Loader dismiss={loading} />
          <Helmet>
            <title>
              {t("app.common.title") +
                ": " +
                seo?.[section]?.[i18n.language]?.title || ""}
            </title>

            {/* Meta tags para SEO */}
            <meta
              name="description"
              content={seo?.[section]?.[i18n.language]?.desc || ""}
            />
            <meta
              name="keywords"
              content={seo?.[section]?.[i18n.language]?.keywords || ""}
            />
            <meta name="author" content="masimka.com" />
            <link rel="alternate" href={enviroment.siteUrl} hrefLang={i18n.language} />

            {/* Meta tags Open Graph (Facebook, Twitter) */}
            <meta
              property="og:title"
              content={seo?.[section]?.[i18n.language]?.title || ""}
            />
            <meta
              property="og:description"
              content={seo?.[section]?.[i18n.language]?.desc || ""}
            />
            <meta
              property="og:image"
              content={`${enviroment.siteUrl}/images/masimka-big.png`}
            />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />

            {/* Meta tags Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content={seo?.[section]?.[i18n.language]?.title || ""}
            />
            <meta
              name="twitter:description"
              content={seo?.[section]?.[i18n.language]?.desc || ""}
            />
            <meta name="twitter:image" content="/images/masimka-big.png" />

            {/* Google Analytics */}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=G-T05RV1737R`}
            />
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-T05RV1737R');
          `}
            </script>
          </Helmet>
          <BrowserRouter>
            <AppContainer>
              <UserContext.Provider value={UserContextValue}>
                <Routing routes={moduleRoutes} />
              </UserContext.Provider>
            </AppContainer>
          </BrowserRouter>
          <LoaderComponent />
        </Box>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
