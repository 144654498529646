import { CustomSnack } from "../../components/snack-message";

interface iProps {
  children: JSX.Element;
}

export default function AppContainer(props: iProps) {
  return (
    <>
      <div className="app-container">{props.children}</div>
      <CustomSnack />
    </>
  );
}
